<template>
	<div class="com-box-content">
		<div class="itemBox" :style="'width:100%;height:'+height+'px;'">
			<div class="lt"></div>
			<div class="tr"></div>
			<div class="rb"></div>
			<div class="lb"></div>
			<div class="text-center base-font-16 text-white" style="padding-top:13px; padding-bottom:7px;">{{title}}</div>
			<slot name="content"></slot>
		</div>
	</div>
</template>
<script>
	export default{
		props: {
		    width: {
		      default: '100%'
		    },
		    height: {
		      default: 306
		    },
		    minHeight: {
		      default: '0px'
		    },
		    title: {
		      default: ''
		    },
		  },
		data(){
			return{
				boxObj:{
					height:'306px',
					title:"访问来源Top10"
				}
			}
		}
	}
</script>

<style lang="scss">
	.com-box-content{
		.itemBox{position:relative;background:rgba(7, 23, 53, 0.67) url('../../assets/images/xsd/bxotopbg.png') top center no-repeat;background-size: 100% 65px;}
		.lt{width:43px;height:41px;background:url('../../assets/images/xsd/tltop.png') top center no-repeat;position:absolute;top:0;left:0;}
		.tr{width:43px;height:41px;background:url('../../assets/images/xsd/trtop.png') top center no-repeat;position:absolute;top:0;right:0;}
		.rb{width:43px;height:41px;background:url('../../assets/images/xsd/rbtop.png') top center no-repeat;position:absolute;bottom:0;right:0;}
		.lb{width:43px;height:41px;background:url('../../assets/images/xsd/lbtop.png') top center no-repeat;position:absolute;left:0;bottom:0;}
	}
	
</style>
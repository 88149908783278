import $http from '@/utils/http'

export function doGet(url) {
    return $http.get(url);
}
export function doPost(url, data) {
    return $http.post(url, data);
}



// 首页-数据看板中间数据
export function centerData() {
	return $http.get('/api/phone/data/centerData')
}

//首页-数据看板左侧数据
export function leftData() {
	return $http.get('/api/phone/data/leftData')
}

//首页-数据看板右侧数据
export function rightData() {
	return $http.get('/api/phone/data/rightData')
}
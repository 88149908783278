<template>
	<div class="baseItemBox text-white">
		<div>
			<BlockBox title="会员统计数据" height="306">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="flex justify-between align-end">
							<!-- <div>
								<div class="hnzbLable flex align-center ">
									<div class="itemLable pointer" @click="nhLableClick(index)"
										:class="nhLableCurrent==index?'checked':''" v-for="(item,index) in nhLable"
										:key="index">
										{{item}}
									</div>
								</div>
							</div> -->
							<div class="base-font-12 text-white" style="opacity: 0.5;">单位：人</div>
						</div>

						<div id="chartFwly" style="height:225px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="最新报修信息" height="326">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div class="flex justify-between align-center margin-top-10 base-font-12">
							<div>未处理：5个</div>
							<div>处理中：8条</div>
							<div>已处理：22条</div>
						</div>
						<div class="shtjBox margin-top-10">
							<div class="swiper">
								<div class="swiper-wrapper">
									<div class="swiper-slide" v-for="(obj,index) in dkList" :key="index">
										<div class="shtjItem base-font-12">
											<el-row>
												<el-col :span="16">
													<div class="ellipsis">{{obj.name}}</div>
												</el-col>
												<el-col :span="8">
													<div class="text-right">{{obj.time}}</div>
												</el-col>
											</el-row>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</BlockBox>
		</div>
		<div class="margin-top-15">
			<BlockBox title="维修人员排行榜" height="285">
				<template #content>
					<div style="padding:0 15px;box-sizing: border-box">
						<div id="chartJxphb" style="height:225px;"></div>
					</div>
				</template>
			</BlockBox>
		</div>
	</div>
</template>

<script>
	import echartMixins from "@/utils/resizeMixins";
	import BlockBox from '@/views/components/blockBox'
	import Swiper from 'swiper/swiper-bundle';
  import { leftData} from "@/api/api"; // 注意引入的是Swiper
	export default {
		name: "cityGreenLand",
		components: {
			BlockBox
		},
		mixins: [echartMixins],
		data() {
			return {
        item:{},
        dkList:[{name:'萧*',time:'2023-12-27 12:08:02'},{name:'张*',time:'2023-12-27 08:05:02'},{name:'李*',time:'2023-12-26 16:04:05'},{name:'程*',time:'2023-12-25 14:02:01'},{name:'刘*敏',time:'2023-12-25 10:42:01'},{name:'张*',time:'2023-12-25 09:22:01'},{name:'奇*辰',time:'2023-12-24 08:22:21'}],
				nhLable: ['访问人数', '访问次数'],
				nhLableCurrent: 0,
				colors: ['#f0792a', '#4b3eeb', '#247bf1', "#67e591", '#05d3f8', '#c0f02a', ],
				// mrhnDay: ['12/05', '12/06', '12/07', '12/08', '12/09'],
			}
		},
		mounted() {
      this.changeData();
		},
		methods: {
      changeData(){
        let _self=this
        leftData().then(res => {
          _self.item=res.data;
          _self.$nextTick(function() {
            _self.drawFwly();
            _self.initSwiper()
            _self.drawJxphb()
          });
        });
      },
			drawFwly() {
				this.chart = this.$echarts.init(document.getElementById("chartFwly"));
        let data=[{name: "7月", value: 0},{name: "8月", value: 0},{name: "9月", value: 203},{name: "10月", value: 502},{name: "11月", value: 865},{name: "12月", value: 1306}]

        let dateList=[];
        let dateValue=[];
        data.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top: 30,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						axisTick: {
							show: false,
						},
						data: dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					yAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [{
						name: '会员数量',
						type: 'line',
						smooth: true,
						data: dateValue,
						showSymbol: false,
						symbol: 'emptyCircle', // 几何圆
						symbolSize: 5,
						itemStyle: {
							color: '#03DBF4'
						},
						areaStyle: { // 区域填充样式
							color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
								type: 'linear',
								x: 0,
								y: 0,
								x2: 0,
								y2: 1,
								colorStops: [{
										offset: 0,
										color: '#03DBF4', // 0% 处的颜色
									},
									{
										offset: 1,
										color: 'rgba(37,234,255,0)' // 100% 处的颜色
									}
								],
								global: false, // 缺省为 false
							}
						},
					}, ]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},

			drawJxphb() {
				this.chart = this.$echarts.init(document.getElementById("chartJxphb"));
        let data=[{name: "牟遥", value: 2},{name: "李明伟", value: 3},{name: "刘亚东", value: 5},{name: "国庆", value: 6},{name: "信福", value: 5},{name: "赵根全", value: 7},{name: "屈言霜", value: 2}]

        let dateList=[];
        let dateValue=[];
        data.forEach(obj=>{
          dateList.push(obj.name);
          dateValue.push(obj.value);
        })
				let option = {
					grid: {
						left: '3%',
						right: '3.5%',
						bottom: '3%',
						top: 5,
						containLabel: true
					},
					tooltip: {
						trigger: 'axis'
					},
					yAxis: {
						type: 'category',

						axisTick: {
							show: false,
						},
						data: dateList,
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#2da3ff'
							}
						}
					},
					xAxis: {
						type: 'value',
						axisTick: {
							show: false,
						},
						axisLabel: {
							textStyle: {
								color: 'rgba(255,255,255,0.7)', //更改坐标轴文字颜色
								fontSize: 10 //更改坐标轴文字大小
							}
						},
						splitLine: { //网格线
							lineStyle: {
								type: 'dotted', //设置网格线类型 dotted：虚线   solid:实线
								color: ['rgba(45,163,255,0.1)']
							},
							show: true //隐藏或显示
						},
						axisLine: {
							show: false, //隐藏或显示
							lineStyle: {
								color: '#ffffff',
								fontSize: 10
							}
						}
					},
					series: [{
						name: '次数',
						type: 'bar',
						barWidth: 9,
						data: dateValue,
						itemStyle: {
							normal: {
								label: {
									show: true, //开启显示
									position: 'right', //在上方显示
									textStyle: {
										//数值样式
										color: 'white',
										fontSize: 12,
									},
								},
							

							color: { // 填充的颜色 // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
								type: 'linear',
								x: 1,
								y: 0,
								x2: 0,
								y2: 0,
								colorStops: [{
										offset: 0,
										color: '#0149FF', // 0% 处的颜色
									},
									{
										offset: 1,
										color: '#0071E5' // 100% 处的颜色
									}
								],
								global: false, // 缺省为 false
							},
							},
						},


					}, ]
				};
				// echart图表自适应
				this.chart.setOption(option)
				window.addEventListener("resize", () => {
					this.chart.resize();
				});
			},

			initSwiper() {
				new Swiper('.swiper', {
					autoplay: true, //可选选项，自动滑动
					direction: 'vertical',
					height: 250, //你的slide高度
					slidesPerView: 5,
				})
			},
			nhLableClick(index) {
				this.nhLableCurrent = index
				this.getGsyshnzb(index + 1 + '')
			}

		}
	}
</script>

<style lang="scss">
	.baseItemBox {
		min-height: 500px;
		padding: 14px 15px 15px 15px;
		box-sizing: border-box;

		.shtjItem {
			width: 100%;
			height: 38px;
			line-height: 38px;
			border: 1px solid #155898;
			line-height: 38px;
			padding: 0 5px;
			box-sizing: border-box;
			cursor: pointer;
		}

		.shtjItem:hover {
			background: #155898;
		}

		.shtjBox {
			height: 250px;

			& .swiper {
				height: 250px;
			}
		}
	}


	.hnzbLable {
		margin-top: 10px;
	}

	.hnzbLable .itemLable {
		line-height: 1;
		border: 1px solid;
		color: #2196f3;
		font-size: 12px;
		padding: 5px 7px;
		border-radius: 1px;
	}

	.hnzbLable .itemLable+.itemLable {
		margin-left: 7px;
	}

	.hnzbLable .itemLable.checked {
		background: #006AF6;
		color: #FFFFFF;
		border-color: #006AF6;
	}
</style>